import React, { useEffect, useState, useRef } from "react";
import Typed from "typed.js";
import { Link } from "react-router-dom";
import { styles } from "../styles";
import { navLinks } from "../constants";
import { menu, close } from "../assets";
import logo from "../assets/logos.png";

const Navbar = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 800) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const options = {
      strings: getStrings(), // Use the appropriate array based on the view
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };

    const typed = new Typed(textRef.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  const getStrings = () => {
    // Define the strings array for mobile view
    const mobileStrings = ["Web Dev", "Programmer", "React Native", "Linux"];

    // Define the strings array for desktop view
    const desktopStrings = [
      "Web Developer",
      "Programmer",
      "React Native Developer",
      "Linux Enthusiast",
    ];

    // Determine the current view based on screen width
    const isMobileView = window.innerWidth < 768;

    // Return the appropriate strings array based on the view
    return isMobileView ? mobileStrings : desktopStrings;
  };

  return (
    <nav
      className={`${
        styles.paddingX
      } w-full flex items-center py-5 fixed top-0 z-20 ${
        scrolled ? "bg-primary" : "bg-transparent"
      }`}
    >
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="w-9 h-9 p-[0.30em] bg-red-500 rounded-full"
          />
          <p className="text-white text-[18px] font-bold cursor-pointer flex">
            Karthik Vijay | &nbsp;
            <span ref={textRef} />
          </p>
        </Link>

        <ul className="list-none hidden sm:flex flex-row gap-10 ml-auto">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.title ? "text-white" : "text-secondary"
              } hover:text-white text-[18px] font-medium cursor-pointer`}
              onClick={() => setActive(nav.title)}
            >
              <a href={`#${nav.id}`}>{nav.title}</a>
            </li>
          ))}
        </ul>
        <div className="hidden sm:flex flex-row gap-10">
          <Link to="https://drive.google.com/file/d/1jBQW2YlByjVcOBqBTDiOum1LtFCConzo/view?usp=sharing">
            <button
              style={{
                backgroundColor: "#b83434",
                borderRadius: "40px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                marginLeft: "20px",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#d63434";
                e.target.style.transition = "0.2s linear";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#b83434";
              }}
            >
              Get Resume
            </button>
          </Link>
        </div>

        <div className="sm:hidden flex flex-1 justify-end items-center">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col gap-4">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-secondary"
                  }`}
                  onClick={() => {
                    setToggle(!toggle);
                    setActive(nav.title);
                  }}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
              <li>
                <Link to="https://drive.google.com/file/d/1jBQW2YlByjVcOBqBTDiOum1LtFCConzo/view?usp=sharing">
                  <button
                    style={{
                      backgroundColor: "#b83434",
                      borderRadius: "40px",
                      padding: "7px",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#d63434";
                      e.target.style.transition = "0.2s linear";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "#b83434";
                    }}
                  >
                    Resume
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
