import React from "react";
import { fallstar, loadgif } from "../assets";

const Loading = () => {
  return (
    <div
      className="flex justify-center items-center h-screen bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${fallstar})` }}
    >
      <img src={loadgif} alt="Loading..." className="w-48 h-48" />
    </div>
  );
};

export default Loading;
