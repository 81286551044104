import {
  mobile,
  backend,
  web,
  javascript,
  typescript,
  html,
  css,
  reactjs,
  redux,
  tailwind,
  nodejs,
  mongodb,
  git,
  tictactoe,
  findweather,
  threejs,
  videtube,
  kudumbashree,
  WebEdit,
  tharang,
  tinkerhub,
  ieee,
  iedc,
  GPACalculator
} from "../assets";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "projects",
    title: "Projects",
  },
  {
    id: "contact",
    title: "Contact",
  }
];

const services = [
  {
    title: "Frontend Web Developer",
    icon: web,
  },
  {
    title: "React Native Developer",
    icon: mobile,
  },
  {
    title: "Backend Developer",
    icon: backend,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Three JS",
    icon: threejs,
  },
  {
    name: "git",
    icon: git,
  },
];

const experiences = [
  {
    title: "Job Fair Volunteer IHRD Tharang",
    company_name: "IHRD Tharang",
    icon: tharang,
    iconBg: "#383E56",
    date: "Feb 2023",
    points: [
      "Configured and tested Network availability in the Campus for the job fair.",
      "Provided technical support for Aptitude test in Neurahire platform for job fair applicants held at College of Engineering Chengannur (CEC)."
    ],
  },
  {
    title: "TinkerHub Makerstations NodeJS Mentor",
    company_name: "TinkerHub",
    icon: tinkerhub,
    iconBg: "#383E56",
    date: "April 2023",
    points: [
      "Mentor for the Node.js learning station.",
      "Mentored 150+ students on the basics of Node.js",
      "In collaboration with Amazon India at College of Engineering Chengannur (CEC)"
    ],
  },
  {
    title: "IEEE Volunteer",
    company_name: "IEEE",
    icon: ieee,
    iconBg: "#383E56",
    date: "2020 - Present",
    points: [
      "World’s largest technical professional organization.",
      "Advances technology for the benefit of humanity.",
      "Publishes journals, organizes conferences, and develops technology standards.",
      "Fosters technological innovation and excellence for the betterment of all people."
    ],
  },
  {
    title: "IEDC Volunteer",
    company_name: "IEDC",
    icon: iedc,
    iconBg: "#383E56",
    date: "2020 - Present",
    points: [
      "IEDC is a platform created by the Kerala Startup Mission (KSUM) for the young minds to exhibit their talents in the field of technology, innovation and entrepreneurship.",
    ],
  },
];

const testimonials = [
];

const projects = [
  {
    name: "Kshree App",
    description:
      "A react native app made for Kudumbashree workers to manage their daily activities.",
    tags: [
      {
        name: "react-native",
        color: "blue-text-gradient",
      },
      {
        name: "supabase",
        color: "pink-text-gradient",
      },
      {
        name: "react-native-paper",
        color: "green-text-gradient",
      }
    ],
    image: kudumbashree,
    source_code_link: "https://github.com/karthikvijay5227/Kshree",
    web_link: "https://github.com/karthikvijay5227/Kshree/releases/tag/v1.0.0",
  },
  {
    name: "Vide Tube",
    description:
      "A simple YouTube clone website made using ReactJS, Tailwind CSS, and  RapidAPI.",
    tags: [
      {
        name: "react",
        color: "blue-text-gradient",
      },
      {
        name: "tailwind",
        color: "pink-text-gradient",
      },
      {
        name: "rapidAPI",
        color: "green-text-gradient",
      }
    ],
    image: videtube,
    source_code_link: "https://github.com/karthikvijay5227/VideTube",
    web_link: "https://vide-tube.netlify.app/",
  },
  {
    name: "GPA Calculator",
    description:
      "A react native app for calculating SGPA based on their credits and grade for each subject.",
    tags: [
      {
        name: "react-native",
        color: "blue-text-gradient",
      },
    ],
    image: GPACalculator,
    source_code_link: "https://github.com/karthikvijay5227/GPACalculator",
    web_link: "https://github.com/karthikvijay5227/GPACalculator/releases/tag/v1.0",
  },
  {
    name: "Tic Tac Toe",
    description:
      "A simple Tic Tac Toe game made using HTML, CSS and JS.",
    tags: [
      {
        name: "html",
        color: "blue-text-gradient",
      },
      {
        name: "css",
        color: "green-text-gradient",
      },
      {
        name: "js",
        color: "pink-text-gradient",
      },
    ],
    image: tictactoe,
    source_code_link: "https://github.com/karthikvijay5227/TicTacToe",
    web_link: "https://karthikvijay5227.github.io/TicTacToe/",
  },
  {
    name: "Find Weather",
    description:
      "A simple weather app made using HTML, CSS, JS and  OpenWeather API.",
    tags: [
      {
        name: "html",
        color: "blue-text-gradient",
      },
      {
        name: "css",
        color: "green-text-gradient",
      },
      {
        name: "js",
        color: "pink-text-gradient",
      },
      {
        name: "openweatherAPI",
        color: "green-text-gradient"
      }
    ],
    image: findweather,
    source_code_link: "https://github.com/karthikvijay5227/FindWeather",
    web_link: "https://karthikvijay5227.github.io/FindWeather/",
  },
  {
    name: "WebEdit",
    description:
      "A simple website made using HTML, CSS, JS to learn and test basic web development.",
    tags: [
      {
        name: "html",
        color: "blue-text-gradient",
      },
      {
        name: "css",
        color: "green-text-gradient",
      },
      {
        name: "js",
        color: "pink-text-gradient",
      },
    ],
    image: WebEdit,
    source_code_link: "https://github.com/karthikvijay5227/WebEdit",
    web_link: "https://karthikvijay5227.github.io/WebEdit/",
  },
];

export { services, technologies, experiences, testimonials, projects };
