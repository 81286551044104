// Layout.js
import { Analytics } from '@vercel/analytics/react';

function Layout({ children }) {
    return (
        <div>
            {children}
            <Analytics />
        </div>
    );
}

export default Layout;
