import { BrowserRouter } from "react-router-dom";
import { useState, useEffect, lazy, Suspense } from "react";
import { Navbar, Hero, StarsCanvas, About, Experience } from "./components";
import { FaArrowUp } from "react-icons/fa";
import Loading from "./components/Loading";
import Layout from "../Layout";

// const Navbar = lazy(() => import("./components/Navbar"));
// const Hero = lazy(() => import("./components/Hero"));
// const About = lazy(() => import("./components/About"));
// const Experience = lazy(() => import("./components/Experience"));
const Tech = lazy(() => import("./components/Tech"));
const Works = lazy(() => import("./components/Works"));
const Contact = lazy(() => import("./components/Contact"));
const Footer = lazy(() => import("./components/Footer"));
const Sider = lazy(() => import("./components/Sider"));

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [isSide, setIsSide] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1080); // Adjust the breakpoint if needed
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSide(window.innerWidth >= 1350); // Adjust the breakpoint if needed
    };

    handleResize(); // Check initial screen size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleEmailClick = () => {
    window.location.href = "mailto:kv52274@gmail.com";
  };

  // Function to handle clicking the phone number
  const handlePhoneClick = () => {
    // Copy the phone number to clipboard
    navigator.clipboard.writeText("(854) 725-7478");

    // Call the dialer
    window.location.href = "tel:+918547257478";
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      passive: false,
    });
  };

  return (
    <BrowserRouter>
      <Layout>
        {isLoading ? ( // Show the Loading component if isLoading is true
          <Loading />
        ) : (
          <div className="relative z-0 bg-primary overflow-x-hidden">
            <div className="bg-hero-pattern bg-cover bg-no-repeat bg-center">
              <Navbar />
              <Hero />
            </div>
            <About />
            <Experience />
            <Suspense fallback={<Loading />}>
              {!isMobile && <Tech />}
              <Works />
              <Contact />
              <div className="mt-1">
                <div
                  className={`flex flex-wrap gap-4 ${
                    isMobile ? "justify-center" : "ml-72"
                  }`}
                >
                  {/* Phone Number */}
                  <div
                    className="bg-tertiary text-white font-medium p-4 rounded-md cursor-pointer transform transition-transform hover:scale-105"
                    onClick={handlePhoneClick}
                    aria-label="Phone Number"
                  >
                    <span className="text-secondary">Phone : </span>
                    (854) 725-7478
                  </div>
                  {/* Email */}
                  <div
                    className="bg-tertiary text-white font-medium p-4 rounded-md cursor-pointer transform transition-transform hover:scale-105"
                    onClick={handleEmailClick}
                    aria-label="Email"
                  >
                    <span className="text-secondary">Mail : </span>
                    kv52274@gmail.com
                  </div>
                </div>
              </div>
              <StarsCanvas />
              <div
                className={`absolute top-100 w-full flex justify-center mt-3 ${
                  isMobile ? "justify-center" : "left-1.5"
                }`}
              >
                <button
                  className="rounded-full bg-red-500 text-white p-2 transform transition-transform hover:scale-110"
                  onClick={scrollToTop}
                  aria-label="Back to top"
                >
                  <span className="sr-only">Back to top</span>
                  <FaArrowUp className="w-5 h-5" />
                </button>
              </div>
              <hr className="mx-auto mt-8 w-3/4" />
              <Footer />
            </Suspense>
            <Suspense fallback={<Loading />}>{isSide && <Sider />}</Suspense>
          </div>
        )}
      </Layout>
    </BrowserRouter>
  );
};

export default App;
